import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Header from "src/components/Basicos/Header";
import Loader from "src/components/Basicos/Loader";
import Modal from "src/components/Funcionalidades/ModalNoticia";
import { axiosApi } from "src/services/axiosInstance";
import FormPessoaFisica from "./PessoaFisica/index";
import FormPessoaJuridica from "./PessoaJuridica/index";
import { useControlContextLocatario } from "src/contexts/LocatarioContext";
import { useControlContextMobile } from "src/contexts/MobileContext";

const FormularioValidateCadastroLocatario: React.FunctionComponent = () => {
    const { locatarioId }: any = useParams();
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [motivo, setMotivo] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [etapa, setEtapa] = useState<any>();
    const location = useLocation();
    const itsView = location.pathname.split('/').includes('view');
    const { setMobile } = useControlContextMobile();
    const {
        setLocPDF
    } = useControlContextLocatario();

    const [valueForm, setValueForm] = useState<any>({
        nome_locatario: null,
        telefone_locatario: null,
        telefone2_locatario: null,
        rg_cnh_locatario: null,
        nacionalidade_locatario: null,
        naturalidade_locatario: null,
        cpf_locatario: null,
        email_locatario: null,
        estado_civil_locatario: null,
        numb_dependentes_locatario: null,
        garantia_locaticia_locatario: null,

        nome_conjuge: null,
        telefone_conjuge: null,
        telefone2_conjuge: null,
        rg_conjuge: null,
        cpf_conjuge: null,
        email_conjuge: null,
        profissao_conjuge: null,
        tipo_atividade_profissional_conjuge: null,
        atividade_profissional_conjuge: null,
        ramo_atividade_conjuge: null,
        renda_mensal_conjuge: null,
        contracheque_conjuge: null,
        contracheque2_conjuge: null,
        contracheque3_conjuge: null,
        carteira_trabalho_conjuge: null,
        imposto_renda_conjuge: null,
        extrato_bancario_conjuge: null,
        extrato_bancario2_conjuge: null,
        extrato_bancario3_conjuge: null,
        outro_conjuge: null,
        nome_empresa_conjuge: null,
        tempo_empresa_conjuge: null,
        cargo_atual_conjuge: null,
        cep_empresa_atual_conjuge: null,
        logradouro_empresa_atual_conjuge: null,
        numero_empresa_atual_conjuge: null,
        bairro_empresa_atual_conjuge: null,
        cidade_empresa_atual_conjuge: null,
        estado_empresa_atual_conjuge: null,
        complemento_empresa_atual_conjuge: null,

        sua_residencia_atual_e: null,
        comprovante_endereco_residencia_atual: null,
        cep_residencia_atual: null,
        logradouro_residencia_atual: null,
        numero_residencia_atual: null,
        bairro_residencia_atual: null,
        cidade_residencia_atual: null,
        estado_residencia_atual: null,
        complemento_residencia_atual: null,
        valor_aluguel_residencia_atual: null,
        tempo_locacao_residencia_atual: null,

        tipo_atividade_profissional: null,
        ramo_atividade: null,
        atividade_profissional: null,
        renda_mensal: null,
        contracheque: null,
        contracheque2: null,
        contracheque3: null,
        carteira_trabalho: null,
        nome_empresa: null,
        tempo_empresa: null,
        cargo_atual: null,
        cep_empresa_atual: null,
        logradouro_empresa_atual: null,
        numero_empresa_atual: null,
        bairro_empresa_atual: null,
        cidade_empresa_atual: null,
        estado_empresa_atual: null,
        complemento_empresa_atual: null,

        codigo_imovel_pretendido: null,
        finalidade_imovel_pretendido: null,
        logradouro_imovel_pretendido: null,
        numero_imovel_pretendido: null,
        complemento_imovel_pretendido: null,
        bairro_imovel_pretendido: null,
        cidade_imovel_pretendido: null,
        estado_imovel_pretendido: null,
        aluguel_imovel_pretendido: null,
        iptu_imovel_pretendido: null,
        condominio_imovel_pretendido: null,
        nome_fiador: null,
        cpf_fiador: null,
        email_fiador: null,
        telefone_fiador: null,
        sede_responsavel_atendimento: null,

        razao_social_locatario: null,
        nome_fantasia_locatario: null,
        cnpj_locatario: null,
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        cartao_inscricao_cnpj: null,
        contrato_social_locatario: null,
        ultimo_aditivo_contrato: null,
        imposto_renda_pj: null,

        codigo_imovel_pretendido_juridico: null,
        preco_locacao_imovel_pretendido: null,
        garantia_imovel_pretendido: null,

        profissao_procurador: null
    });

    const [locatarioValue, setLocatarioValue] = useState<any>();

    const [sections, setSections] = useState<any>({
        identificacao_pessoa_fisica: null,
        identificacao_pessoa_juridica: null,
        conjuge: null,
        residencia_atual: null,
        atividade_profissional: null,
        imovel_pretendido: null,
        representantes_legal: null,
        imovel_pretendido_juridico: null,
        procurador: null,
        outros_dados: null
    });

    let nulos = [null, undefined];

    const handleClick = (name: string, value: boolean) => {
        setValueForm({
            ...valueForm,
            [name]: value
        });
    };

    const handleRejected = (value: boolean) => {
        setOpen(value);
    };

    const onchange = (e: any) => {
        const { name, value } = e.target;
        setLocatarioValue({
            ...locatarioValue,
            [name]: value
        });
        if (name === 'motivoReprovacao') {
            setMotivo(value);
        }
    };

    const onSubmitReprovacao = async (e: any) => {
        try {
            e.preventDefault();

            if (locatarioId !== null && motivo !== '') {
                setLoader(true)
                const data = {
                    locatario_id: locatarioId,
                    etapa_id: location.pathname.split('/')[5] ? 4 : 3,
                    comentario: motivo ?? null
                }
                await axiosApi.post(`locatario/status/reprovado`, data);
                setOpen(false);
                history.goBack();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getLocatario = async () => {
        try {
            if (locatarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/locatario/${locatarioId}`);
                setLocPDF(data?.data)
                setLocatarioValue(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getValidacao = async () => {
        try {
            if (locatarioId !== null) {
                var analise = location.pathname.split('/');
                var etapaId = analise[5] == 'analise-diretoria' ? 4 : 3;
                setEtapa(etapaId)
                setLoader(true)
                const { data } = await axiosApi.get(`/validacao/locatario/${locatarioId}?etapa=${etapaId}`);
                // delete data?.data.novo_locatario;
                const validacoes = data?.data;
                setValueForm({ ...validacoes });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const selectOneSecao = (secao: number, value: boolean) => {
        switch (secao) {
            case 0:
                const identificacaoPessoaFisicaArray = [];
                identificacaoPessoaFisicaArray.push(
                    { [`nome_locatario`]: value },
                    { [`telefone_locatario`]: value },
                    { [`telefone2_locatario`]: value },
                    { [`rg_cnh_frente_locatario`]: value },
                    { [`rg_cnh_verso_locatario`]: value },
                    { [`cpf_locatario`]: value },
                    { [`email_locatario`]: value },
                    { [`estado_civil_locatario`]: value },
                    { [`numb_dependentes_locatario`]: value },
                    { [`garantia_locaticia_locatario`]: value }
                );

                if (!nulos.includes(locatarioValue?.informacoes_basicas?.naturalidade)) {
                    identificacaoPessoaFisicaArray.push({ [`naturalidade_locatario`]: value });
                }
                if (!nulos.includes(locatarioValue?.informacoes_basicas?.nacionalidade)) {
                    identificacaoPessoaFisicaArray.push({ [`nacionalidade_locatario`]: value });
                }

                const mergeObjectIdentificacaoPessoaFisica = identificacaoPessoaFisicaArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoPessoaFisica });
            case 1:
                const atividadeProfissionalConjugeArray = [];
                atividadeProfissionalConjugeArray.push(
                    { [`nome_conjuge`]: value },
                    { [`telefone_conjuge`]: value },
                    { [`telefone2_conjuge`]: value },
                    { [`rg_cnh_frente_conjuge`]: value },
                    { [`rg_cnh_verso_conjuge`]: value },
                    { [`cpf_conjuge`]: value },
                    { [`email_conjuge`]: value },
                    { [`tipo_atividade_profissional_conjuge`]: value },
                    { [`atividade_profissional_conjuge`]: value },
                    { [`renda_mensal_conjuge`]: value },
                    { [`ramo_atividade_conjuge`]: value }
                );

                if (!nulos.includes(locatarioValue?.conjuge?.informacoes_basicas.naturalidade)) {
                    atividadeProfissionalConjugeArray.push({ [`naturalidade_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.informacoes_basicas.nacionalidade)) {
                    atividadeProfissionalConjugeArray.push({ [`nacionalidade_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.nome_empresa)) {
                    atividadeProfissionalConjugeArray.push({ [`nome_empresa_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
                    atividadeProfissionalConjugeArray.push({ [`tempo_empresa_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.cargo_atual)) {
                    atividadeProfissionalConjugeArray.push({ [`cargo_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_cartao_credito_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`contracheque_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`contracheque2_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`contracheque3_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`carteira_trabalho_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`imposto_renda_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_bancario_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_bancario2_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`extrato_bancario3_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
                    atividadeProfissionalConjugeArray.push({ [`outro_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.cep)) {
                    atividadeProfissionalConjugeArray.push({ [`cep_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
                    atividadeProfissionalConjugeArray.push({ [`logradouro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.numero)) {
                    atividadeProfissionalConjugeArray.push({ [`numero_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.bairro)) {
                    atividadeProfissionalConjugeArray.push({ [`bairro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.cidade)) {
                    atividadeProfissionalConjugeArray.push({ [`cidade_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.estado)) {
                    atividadeProfissionalConjugeArray.push({ [`estado_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.complemento)) {
                    atividadeProfissionalConjugeArray.push({ [`complemento_empresa_atual_conjuge`]: value });
                }

                const mergeObjectAtividadeProfissionalConjuge = atividadeProfissionalConjugeArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectAtividadeProfissionalConjuge });
            case 2:
                const enderecoArray = [];
                enderecoArray.push(
                    { [`sua_residencia_atual_e`]: value },
                    { [`comprovante_endereco_residencia_atual`]: value },
                    { [`cep_residencia_atual`]: value },
                    { [`logradouro_residencia_atual`]: value },
                    { [`numero_residencia_atual`]: value },
                    { [`bairro_residencia_atual`]: value },
                    { [`cidade_residencia_atual`]: value },
                    { [`estado_residencia_atual`]: value }
                );

                if (!nulos.includes(locatarioValue?.endereco?.complemento)) {
                    enderecoArray.push({ [`complemento_residencia_atual`]: value });

                }
                if (!nulos.includes(locatarioValue?.residencia_atual?.valor_aluguel)) {
                    enderecoArray.push({ [`valor_aluguel_residencia_atual`]: value });

                }
                if (!nulos.includes(locatarioValue?.residencia_atual?.tempo_locacao)) {
                    enderecoArray.push({ [`tempo_locacao_residencia_atual`]: value });

                }

                const mergeObjectEndereco = enderecoArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectEndereco });

            case 3:
                const atividadeProfissionalArray = [];
                atividadeProfissionalArray.push(
                    { [`tipo_atividade_profissional`]: value },
                    { [`renda_mensal`]: value }
                );
                if (!nulos.includes(locatarioValue?.atividade_profissional?.outra_atividade_profissional)) {
                    atividadeProfissionalArray.push({ [`atividade_profissional`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.ramo_atividade)) {
                    atividadeProfissionalArray.push({ [`ramo_atividade`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_cartao_credito`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque2`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque3`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_ctps?.nome)) {
                    atividadeProfissionalArray.push({ [`carteira_trabalho`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                    atividadeProfissionalArray.push({ [`imposto_renda`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario2`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario3`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_outro?.nome)) {
                    atividadeProfissionalArray.push({ [`outro`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.nome_empresa)) {
                    atividadeProfissionalArray.push({ [`nome_empresa`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.tempo_empresa?.descricao)) {
                    atividadeProfissionalArray.push({ [`tempo_empresa`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.cargo_atual)) {
                    atividadeProfissionalArray.push({ [`cargo_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.cep)) {
                    atividadeProfissionalArray.push({ [`cep_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.logradouro)) {
                    atividadeProfissionalArray.push({ [`logradouro_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.numero)) {
                    atividadeProfissionalArray.push({ [`numero_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.bairro)) {
                    atividadeProfissionalArray.push({ [`bairro_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.cidade)) {
                    atividadeProfissionalArray.push({ [`cidade_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.estado)) {
                    atividadeProfissionalArray.push({ [`estado_empresa_atual`]: value });
                }
                if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.complemento)) {
                    atividadeProfissionalArray.push({ [`complemento_empresa_atual`]: value });
                }


                const mergeObjectAtividadeProfissional = atividadeProfissionalArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectAtividadeProfissional });
            case 4:
                const imovelPretendidoPessoaFisicaArray = [];
                imovelPretendidoPessoaFisicaArray.push(
                    { [`codigo_imovel_pretendido`]: value },
                    { [`finalidade_imovel_pretendido`]: value },
                    { [`logradouro_imovel_pretendido`]: value },
                    { [`numero_imovel_pretendido`]: value },
                    { [`bairro_imovel_pretendido`]: value },
                    { [`cidade_imovel_pretendido`]: value },
                    { [`estado_imovel_pretendido`]: value },
                    { [`aluguel_imovel_pretendido`]: value },
                    { [`iptu_imovel_pretendido`]: value },
                    { [`condominio_imovel_pretendido`]: value },
                    { [`sede_responsavel_atendimento`]: value }
                );

                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.complemento)) {
                    imovelPretendidoPessoaFisicaArray.push({ [`complemento_imovel_pretendido`]: value });
                }

                if (!nulos.includes(locatarioValue?.sede_responsavel?.nome)) {
                    imovelPretendidoPessoaFisicaArray.push({ [`sede_responsavel_atendimento`]: value });
                }
                const mergeObjectImovelPretendidoPessoaFisica = imovelPretendidoPessoaFisicaArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectImovelPretendidoPessoaFisica });

            case 5:
                const identificacaoPessoaJuridicaArray = [];
                identificacaoPessoaJuridicaArray.push(
                    { [`razao_social_locatario`]: value },
                    { [`nome_fantasia_locatario`]: value },
                    { [`cnpj_locatario`]: value },
                    { [`email_locatario`]: value },
                    { [`telefone_locatario`]: value },
                    { [`telefone2_locatario`]: value },
                    { [`cep`]: value },
                    { [`logradouro`]: value },
                    { [`numero`]: value },
                    { [`bairro`]: value },
                    { [`cidade`]: value },
                    { [`estado`]: value },
                    { [`garantia_locaticia_locatario`]: value },
                );

                if (!nulos.includes(locatarioValue?.endereco?.complemento)) {
                    identificacaoPessoaJuridicaArray.push({ [`complemento`]: value });
                }
                if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`cartao_inscricao_cnpj`]: value });
                }
                if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_contrato_social?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`contrato_social_locatario`]: value });
                }
                if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`ultimo_aditivo_contrato`]: value });
                }
                if (!nulos.includes(locatarioValue?.arquivos_empresa?.imposto_renda_pj?.nome)) {
                    identificacaoPessoaJuridicaArray.push({ [`imposto_renda_pj`]: value });
                }

                const mergeObjectIdentificacaoPessoaJuridica = identificacaoPessoaJuridicaArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoPessoaJuridica });
            case 6:
                const representantes = [] as any;
                const representantesArray = Object.keys(locatarioValue?.representantes).map((item, index) => {
                    const numberRepresentante = index + 1;
                    representantes.push(
                        { [`nome_representante${numberRepresentante}`]: value },
                        { [`email_representante${numberRepresentante}`]: value },
                        { [`telefone1_representante${numberRepresentante}`]: value },
                        { [`telefone2_representante${numberRepresentante}`]: value },
                        { [`rg_cnh_frente_representante${numberRepresentante}`]: value },
                        { [`rg_cnh_verso_representante${numberRepresentante}`]: value },
                        { [`cpf_representante${numberRepresentante}`]: value },
                        { [`estado_civil_representante${numberRepresentante}`]: value },
                        { [`cep_representante${numberRepresentante}`]: value },
                        { [`logradouro_representante${numberRepresentante}`]: value },
                        { [`numero_representante${numberRepresentante}`]: value },
                        { [`complemento_representante${numberRepresentante}`]: value },
                        { [`bairro_representante${numberRepresentante}`]: value },
                        { [`cidade_representante${numberRepresentante}`]: value },
                        { [`estado_representante${numberRepresentante}`]: value },
                        { [`comprovante_residencia_representante${numberRepresentante}`]: value },
                        { [`imposto_renda_pj_representante${numberRepresentante}`]: value },
                        { [`extrato_bancario_representante${numberRepresentante}`]: value },
                        { [`extrato_bancario2_representante${numberRepresentante}`]: value },
                        { [`extrato_bancario3_representante${numberRepresentante}`]: value },
                        { [`outros_documentos_representante${numberRepresentante}`]: value },
                        { [`naturalidade_representante${numberRepresentante}`]: value },
                        { [`nacionalidade_representante${numberRepresentante}`]: value },
                    );

                    const mergeObjectRepresentante = representantes.reduce(function (result: any, current: any) {
                        return Object.assign(result, current);
                    }, {});

                    return mergeObjectRepresentante;

                });
                const lastElem = representantesArray[representantesArray.length - 1];
                return setValueForm({ ...valueForm, ...lastElem });
            case 7:
                const imovelPretendidoJuridicoArray = [];
                imovelPretendidoJuridicoArray.push(
                    { [`codigo_imovel_pretendido_juridico`]: value }
                );


                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.endereco)) {
                    imovelPretendidoJuridicoArray.push({ [`logradouro_imovel_pretendido_juridico`]: value });
                }
                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.numero)) {
                    imovelPretendidoJuridicoArray.push({ [`numero_imovel_pretendido_juridico`]: value });
                }
                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.complemento)) {
                    imovelPretendidoJuridicoArray.push({ [`complemento_imovel_pretendido_juridico`]: value });
                }
                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.bairro)) {
                    imovelPretendidoJuridicoArray.push({ [`bairro_imovel_pretendido_juridico`]: value });
                }
                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.cidade)) {
                    imovelPretendidoJuridicoArray.push({ [`cidade_imovel_pretendido_juridico`]: value });
                }
                if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.estado)) {
                    imovelPretendidoJuridicoArray.push({ [`estado_imovel_pretendido_juridico`]: value });
                }

                const mergeObjectImovelPretendidoJuridico = imovelPretendidoJuridicoArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectImovelPretendidoJuridico });
            case 8:
                const procuradorArray = [];
                procuradorArray.push(
                    { [`nome_procurador`]: value },
                    { [`telefone_procurador`]: value },
                    { [`telefone2_procurador`]: value },
                    { [`rg_cnh_frente_procurador`]: value },
                    { [`rg_cnh_verso_procurador`]: value },
                    { [`cpf_procurador`]: value },
                    { [`email_procurador`]: value },
                    { [`estado_civil_procurador`]: value },
                    { [`cep_procurador`]: value },
                    { [`logradouro_procurador`]: value },
                    { [`numero_procurador`]: value },
                    { [`bairro_procurador`]: value },
                    { [`cidade_procurador`]: value },
                    { [`estado_procurador`]: value },
                    { [`documento_procurador`]: value },
                );

                if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.naturalidade)) {
                    procuradorArray.push({ [`naturalidade_procurador`]: value });
                }
                if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.nacionalidade)) {
                    procuradorArray.push({ [`nacionalidade_procurador`]: value });
                }
                if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.profissao)) {
                    procuradorArray.push({ [`profissao_procurador`]: value });
                }
                if (!nulos.includes(locatarioValue?.procurador?.endereco?.complemento)) {
                    procuradorArray.push({ [`complemento_procurador`]: value });
                }

                const mergeObjectProcurador = procuradorArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectProcurador });
            case 9:
                const outrosDadosArray = [];
                outrosDadosArray.push(
                    { [`nome_fiador`]: value },
                    { [`cpf_fiador`]: value },
                    { [`email_fiador`]: value },
                    { [`telefone_fiador`]: value },
                    { [`telefone2_fiador`]: value }
                );

                const mergeObjectOutrosDados = outrosDadosArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectOutrosDados });

        }
    }

    const labelsRepresentantes = () => {
        const labelsRepresentantes = [] as any;
        Object.keys(locatarioValue?.representantes ?? []).map((item, index) => {
            const numberRepresentante = index + 1;
            return labelsRepresentantes.push(
                `nome_representante${numberRepresentante}`,
                `email_representante${numberRepresentante}`,
                `telefone1_representante${numberRepresentante}`,
                `telefone2_representante${numberRepresentante}`,
                `rg_cnh_frente_representante${numberRepresentante}`,
                `rg_cnh_verso_representante${numberRepresentante}`,
                `cpf_representante${numberRepresentante}`,
                `estado_civil_representante${numberRepresentante}`,
                `cep_representante${numberRepresentante}`,
                `logradouro_representante${numberRepresentante}`,
                `numero_representante${numberRepresentante}`,
                `complemento_representante${numberRepresentante}`,
                `bairro_representante${numberRepresentante}`,
                `cidade_representante${numberRepresentante}`,
                `estado_representante${numberRepresentante}`,
                `comprovante_residencia_representante${numberRepresentante}`,
                `imposto_renda_pj_representante${numberRepresentante}`,
                `extrato_bancario_representante${numberRepresentante}`,
                `extrato_bancario2_representante${numberRepresentante}`,
                `extrato_bancario3_representante${numberRepresentante}`,
                `outros_documentos_representante${numberRepresentante}`,
                `nacionalidade_representante${numberRepresentante}`,
                `naturalidade_representante${numberRepresentante}`,
            );
        });
        return labelsRepresentantes;
    }

    const secaoItsFull = (secao: number) => {

        const values = [
            // 0 - Identificação Locatario
            [
                "nome_locatario",
                "telefone_locatario",
                "telefone2_locatario",
                "rg_cnh_frente_locatario",
                "rg_cnh_verso_locatario",
                "cpf_locatario",
                "email_locatario",
                "estado_civil_locatario",
                "numb_dependentes_locatario",
                "garantia_locaticia_locatario"
            ],
            //1 - Cônjuge
            [
                "nome_conjuge",
                "telefone_conjuge",
                "telefone2_conjuge",
                "rg_cnh_frente_conjuge",
                "rg_cnh_verso_conjuge",
                "cpf_conjuge",
                "email_conjuge",
                "tipo_atividade_profissional_conjuge",
                "atividade_profissional_conjuge",
                "renda_mensal_conjuge",
                "ramo_atividade_conjuge"
            ],
            //2 - Residência Atual
            [
                "sua_residencia_atual_e",
                "comprovante_endereco_residencia_atual",
                "cep_residencia_atual",
                "logradouro_residencia_atual",
                "numero_residencia_atual",
                "bairro_residencia_atual",
                "cidade_residencia_atual",
                "estado_residencia_atual"
            ],
            // 3 - Atividade Profissional
            [
                "tipo_atividade_profissional",
                "renda_mensal",
            ],
            //4 - Imóvel Pretendido
            [
                // "codigo_imovel_pretendido",
                // "finalidade_imovel_pretendido",
                // "logradouro_imovel_pretendido",
                // "numero_imovel_pretendido",
                // "bairro_imovel_pretendido",
                // "cidade_imovel_pretendido",
                // "estado_imovel_pretendido",
                // "aluguel_imovel_pretendido",
                // "iptu_imovel_pretendido",
                // "condominio_imovel_pretendido",
                // "sede_responsavel_atendimento",
            ],
            // pessoa juridica
            //05
            [
                "razao_social_locatario",
                "nome_fantasia_locatario",
                "cnpj_locatario",
                "email_locatario",
                "telefone_locatario",
                "telefone2_locatario",
                "cep",
                "logradouro",
                "numero",
                "bairro",
                "cidade",
                "estado",
                "garantia_locaticia_locatario"
            ],
            //06
            labelsRepresentantes(),
            //07
            [
                "codigo_imovel_pretendido_juridico",
            ],
            //08
            [
                "nome_procurador",
                "telefone_procurador",
                "telefone2_procurador",
                "rg_cnh_frente_procurador",
                "rg_cnh_verso_procurador",
                "cpf_procurador",
                "email_procurador",
                "estado_civil_procurador",
                "documento_procurador_arquivo",
                "cep_procurador",
                "logradouro_procurador",
                "numero_procurador",
                "bairro_procurador",
                "cidade_procurador",
                "estado_procurador",
            ],
            //09
            [
                // "nome_fiador",
                // "cpf_fiador",
                // "email_fiador",
                // "telefone_fiador",
                // "telefone2_fiador"
            ],

        ];


        if (!nulos.includes(locatarioValue?.informacoes_basicas?.naturalidade)) {
            values[0].push("naturalidade_locatario");
        }
        if (!nulos.includes(locatarioValue?.informacoes_basicas?.nacionalidade)) {
            values[0].push("nacionalidade_locatario");
        }


        if (!nulos.includes(locatarioValue?.conjuge?.informacoes_basicas.naturalidade)) {
            values[1].push("naturalidade_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.informacoes_basicas.nacionalidade)) {
            values[1].push("nacionalidade_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.nome_empresa)) {
            values[1].push("nome_empresa_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
            values[1].push("tempo_empresa_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.cargo_atual)) {
            values[1].push("cargo_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
            values[1].push("extrato_cartao_credito_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            values[1].push("contracheque_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            values[1].push("contracheque2_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            values[1].push("contracheque3_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
            values[1].push("carteira_trabalho_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            values[1].push("imposto_renda_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            values[1].push("extrato_bancario_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            values[1].push("extrato_bancario2_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            values[1].push("extrato_bancario3_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
            values[1].push("outro_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.cep)) {
            values[1].push("cep_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
            values[1].push("logradouro_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.numero)) {
            values[1].push("numero_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.bairro)) {
            values[1].push("bairro_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.cidade)) {
            values[1].push("cidade_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.estado)) {
            values[1].push("estado_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.endereco?.complemento)) {
            values[1].push("complemento_empresa_atual_conjuge");
        }
        if (!nulos.includes(locatarioValue?.conjuge?.atividade_profissional?.ramo_atividade)) {
            values[3].push("ramo_atividade_conjuge");
        }


        if (!nulos.includes(locatarioValue?.endereco?.complemento)) {
            values[2].push("complemento_residencia_atual");
        }
        if (!nulos.includes(locatarioValue?.residencia_atual?.valor_aluguel)) {
            values[2].push("valor_aluguel_residencia_atual");
        }
        if (!nulos.includes(locatarioValue?.residencia_atual?.tempo_locacao)) {
            values[2].push("tempo_locacao_residencia_atual");
        }


        if (!nulos.includes(locatarioValue?.atividade_profissional?.outra_atividade_profissional)) {
            values[3].push("atividade_profissional");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.ramo_atividade)) {
            values[3].push("ramo_atividade");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
            values[3].push("extrato_cartao_credito");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            values[3].push("contracheque");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            values[3].push("contracheque2");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            values[3].push("contracheque3");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_ctps?.nome)) {
            values[3].push("carteira_trabalho");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            values[3].push("imposto_renda");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            values[3].push("extrato_bancario");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            values[3].push("extrato_bancario2");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            values[3].push("extrato_bancario3");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.arquivo_outro?.nome)) {
            values[3].push("outro");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.nome_empresa)) {
            values[3].push("nome_empresa");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.tempo_empresa?.descricao)) {
            values[3].push("tempo_empresa");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.cargo_atual)) {
            values[3].push("cargo_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.cep)) {
            values[3].push("cep_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.logradouro)) {
            values[3].push("logradouro_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.numero)) {
            values[3].push("numero_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.bairro)) {
            values[3].push("bairro_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.cidade)) {
            values[3].push("cidade_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.estado)) {
            values[3].push("estado_empresa_atual");
        }
        if (!nulos.includes(locatarioValue?.atividade_profissional?.endereco?.complemento)) {
            values[3].push("complemento_empresa_atual");
        }

        // if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.complemento)) {
        //     values[4].push("complemento_imovel_pretendido");
        // }
        // if (locatarioValue?.tipo_garantia_id === 2) {
        //     values[9].push("nome_fiador");
        //     values[9].push("cpf_fiador");
        //     values[9].push("email_fiador");
        //     values[9].push("telefone_fiador");
        //     values[9].push("telefone2_fiador");
        // }

        // if (!nulos.includes(locatarioValue?.fiador?.informacoes_basicas?.nome)) {
        //     values[4].push("nome_fiador");
        // }
        // if (!nulos.includes(locatarioValue?.fiador?.informacoes_basicas?.cpf)) {
        //     values[4].push("cpf_fiador");
        // }
        // if (!nulos.includes(locatarioValue?.fiador?.informacoes_basicas?.email)) {
        //     values[4].push("email_fiador");
        // }
        // if (!nulos.includes(locatarioValue?.fiador?.informacoes_basicas?.telefone)) {
        //     values[4].push("telefone_fiador");
        // }

        // if (!nulos.includes(locatarioValue?.sede_responsavel?.nome)) {
        //     values[4].push("sede_responsavel_atendimento");
        // }


        if (!nulos.includes(locatarioValue?.endereco?.complemento)) {
            values[5].push("complemento");
        }
        if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)) {
            values[5].push("cartao_inscricao_cnpj");
        }
        if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_contrato_social?.nome)) {
            values[5].push("contrato_social_locatario");
        }
        if (!nulos.includes(locatarioValue?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)) {
            values[5].push("ultimo_aditivo_contrato");
        }
        if (!nulos.includes(locatarioValue?.arquivos_empresa?.imposto_renda_pj?.nome)) {
            values[5].push("imposto_renda_pj");
        }


        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.endereco)) {
            values[7].push("logradouro_imovel_pretendido_juridico");
        }
        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.numero)) {
            values[7].push("numero_imovel_pretendido_juridico");
        }
        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.complemento)) {
            values[7].push("complemento_imovel_pretendido_juridico");
        }
        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.bairro)) {
            values[7].push("bairro_imovel_pretendido_juridico");
        }
        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.cidade)) {
            values[7].push("cidade_imovel_pretendido_juridico");
        }
        if (!nulos.includes(locatarioValue?.imovel_pretendido?.imovel?.estado)) {
            values[7].push("estado_imovel_pretendido_juridico");
        }

        // if (locatarioValue?.tipo_garantia_id === 2) {
        //     values[7].push("nome_fiador");
        //     values[7].push("cpf_fiador");
        //     values[7].push("email_fiador");
        //     values[7].push("telefone_fiador");
        // }

        if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.naturalidade)) {
            values[8].push("naturalidade_procurador");
        }
        if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.nacionalidade)) {
            values[8].push("nacionalidade_procurador");
        }
        if (!nulos.includes(locatarioValue?.procurador?.informacao_basica?.profissao)) {
            values[8].push("profissao_procurador");
        }
        if (!nulos.includes(locatarioValue?.procurador?.endereco?.complemento)) {
            values[8].push("complemento_procurador");
        }

        const isFullTrue = Object.values(values[secao]).every(item => valueForm[`${item}`] === true);
        const isFullFalse = Object.values(values[secao]).every(item => valueForm[`${item}`] === false);

        return isFullTrue && !isFullFalse ? isFullTrue : !isFullTrue && isFullFalse ? !isFullFalse : null;
    }
    const ComponentFormulario = (pessoaId: number) => {
        switch (pessoaId) {
            case 1: //63 campos
                return (
                    <FormPessoaFisica
                        locatario={locatarioValue}
                        campos={valueForm}
                        handleClick={handleClick}
                        selectSection={selectOneSecao}
                        sections={sections}
                        onChange={onchange}
                        openModal={handleRejected}
                        itsView={itsView}
                        etapaId={etapa}
                    />);
            case 2: //41 campos
                return (
                    <FormPessoaJuridica
                        locatario={locatarioValue}
                        campos={valueForm}
                        handleClick={handleClick}
                        selectSection={selectOneSecao}
                        sections={sections}
                        onChange={onchange}
                        openModal={handleRejected}
                        itsView={itsView}
                        etapaId={etapa}
                    />);
            default:
                return null;
        }
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        getValidacao();
        getLocatario();
        onTop();
    }, []);

    useEffect(() => {
        setSections({
            ...sections,
            identificacao_pessoa_fisica: secaoItsFull(0),
            conjuge: secaoItsFull(1),
            residencia_atual: secaoItsFull(2),
            atividade_profissional: secaoItsFull(3),
            imovel_pretendido: secaoItsFull(4),
            identificacao_pessoa_juridica: secaoItsFull(5),
            representantes_legal: secaoItsFull(6),
            imovel_pretendido_juridico: secaoItsFull(7),
            procurador: secaoItsFull(8),
        });
    }, [valueForm]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div>
                    <Modal
                        isOpen={open}
                        isClose={() => setOpen(false)}
                        headerStyle={''}
                        closeOnOutsideClick={true}
                        widthModal={`40%`}
                        modalStyle={`modal-style bg-white rounded-lg py-5 px-10`}
                    >
                        <div>
                            <form onSubmit={onSubmitReprovacao} className={`flex flex-col items-center`}>
                                <div className="flex">
                                    <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Adicione o motivo da reprovação</p>
                                </div>
                                <div className="flex w-full">
                                    <textarea
                                        rows={2}
                                        name={`motivoReprovacao`}
                                        value={motivo ?? null}
                                        style={{ maxHeight: "15rem", minHeight: "3.5rem" }}
                                        className="block p-4 w-full h-20 mt-1 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                        placeholder={'Dê detalhes do porquê o cadastro foi reprovado'}
                                        required={true}
                                        onChange={onchange}
                                    />
                                </div>
                                <div className="flex w-full justify-center">
                                    <button
                                        type="submit"
                                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                                    >
                                        Enviar para o locatário
                                    </button>
                                </div>
                            </form>

                        </div>
                    </Modal>
                    {ComponentFormulario(locatarioValue?.tipo_locatario_id)}
                </div>
            </Loader>
        </Fragment>
    );
}

export default FormularioValidateCadastroLocatario;